import React from "react";
import ReactDOM from "react-dom";

import "./styles.css";
import axios from "axios";
import ModalRegister from "./components/RegisterWaitingList/ModalRegister";
import { Button } from "@material-ui/core";

const api = axios.create({
  baseURL: "https://wallet-backend-1-b0tm.onrender.com",
  withCredentials: true,
});

function App() {
  api.get("/health-check").then((response) => {
    console.log(response.data);
  });

  const [showModal, setShowModal] = React.useState(false);
  const handleClose = () => setShowModal(false);
  const year = new Date().getFullYear();
  return (
    <>
      <div className="App">
        <img
          src="/under-development.png"
          className="img-under-development"
          alt="Em desenvolvimento"
        />
        <div className="logo-title">
          <img
            src="/logo.svg"
            className="logo"
            width={32}
            height={32}
            alt="Logo da DogInvest"
          />{" "}
          <span>DogInvest</span>
        </div>
        <p className="dg-subtitle">
          Seu companheiro fiel na hora de acompanhar todos os seus
          investimentos.
        </p>
        <p className="dg-description">
          Acompanhe a evolução dos seus investimentos de forma simples e
          intuitiva.
        </p>
        <Button
          className="btn-home"
          variant="contained"
          color="secondary"
          onClick={() => setShowModal(true)}
          style={{ marginTop: 20 }}
        >
          Cadastre-se na fila de espera
        </Button>

        <div className="preview">
          <p>Previsão de lançamento da versão beta: 1 de Julho de 2025</p>
        </div>
      </div>
      <footer>
        <p className="dg-footer">
          © { year } DogInvest. Todos os direitos reservados.
        </p>
      </footer>
      {showModal && (
        <ModalRegister show={showModal} handleClose={handleClose} />
      )}
    </>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
